import React, {useEffect, useState} from "react"
import {graphql} from "gatsby"
import {STRAPI_URL} from "../pages";
import cx from "classnames";
import ScaleText from "react-scale-text";
import {makeStyles} from "@mui/styles";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Seo from "../components/seo";
import steam from "./../assets/media_logos/Steam.svg";
import appStore from "./../assets/media_logos/AppStore.svg";
import playMarket from "./../assets/media_logos/PlayMarket.svg";
import meta from "./../assets/media_logos/Meta.svg";
import nintendoEshop from "./../assets/media_logos/NintendoEshop.svg";
import xboxStore from "./../assets/media_logos/XboxStore.svg";
import playstationStore from "./../assets/media_logos/PlaystationStore.svg";

const useStyles = makeStyles((theme) => ({
    blockContainer: {
        position: 'absolute',
        left: 0,
        height: 'calc(100dvh - 52px)',
        display: 'flex',
        opacity: 1,
        transition: 'opacity 1200ms linear',
        flexDirection: 'column',
        width: '100vw',
        overflow: 'auto',

        [theme.breakpoints.up('mobile')]: {
            height: 'calc(100dvh - 100px)',
        },
        [theme.breakpoints.up('laptop')]: {
            width: 'calc(100vw - 100px)',
            left: 100,
        },
    },
    hideBlock: {
        opacity: 0,
        transition: 'opacity 600ms linear',
    },
    textContentContainer: {
        padding: 0,
        width: '100%',
        position: 'relative',

        [theme.breakpoints.up('tablet')]: {
            padding: '0px 60px 70px',
        },
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        maxWidth: 900,
        width: '100%',
        gap: 12,
        zIndex: 2,

        [theme.breakpoints.down('tablet')]: {
            padding: '0 20px',
        },
    },
    title: {
        color: theme.palette.grey.second,
        fontFamily: 'Oswald',
        textTransform: "uppercase",
        lineHeight: 1,
        fontWeight: 500,
        fontSize: 48,
        marginTop: 120,

        [theme.breakpoints.up('mobile')]: {
            fontSize: 64,
        },
        [theme.breakpoints.up('tablet')]: {
            fontSize: 100,
        },
        [theme.breakpoints.up('laptop')]: {
            fontSize: 120,
        },

        [theme.breakpoints.down('tablet')]: {
            marginTop: 20,
        },
    },
    smallText: {
        color: theme.palette.grey.second,
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 18,
        fontStyle: `italic`,
        marginTop: 28,
    },
    subtitle: {
        color: theme.palette.grey.second,
        fontFamily: 'Oswald',
        textTransform: "uppercase",
        lineHeight: 1,
        fontWeight: 500,
        fontSize: 28,

        [theme.breakpoints.up('tablet')]: {
            fontSize: 32,
        },
    },
    text: {
        color: theme.palette.grey.second,
        fontFamily: 'Montserrat',
        fontSize: 16,
        lineHeight: 1.3,
        maxWidth: 800,
        padding: '0 32px',

        [theme.breakpoints.down('laptop')]: {
            padding: '0 20px',
        },
        '& h6': {
            fontSize: 32,
            padding: '60px 0 20px',
            textAlign: 'center',
            fontFamily: 'Oswald',
            textTransform: "uppercase",
            lineHeight: 1,
            fontWeight: 500,

            [theme.breakpoints.down('laptop')]: {
                padding: '36px 0 20px',
            },
        }
    },
    textMaxWidth: {
        padding: 0,
        [theme.breakpoints.up('tablet')]: {
            width: 400,
        },
    },
    detailContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        height: '100%',
        width: null,
        alignItems: 'center',
        marginTop: 48,

        [theme.breakpoints.up('tablet')]: {
            width: '100%'
        },
    },
    imagesContainer: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        marginTop: 60,

        [theme.breakpoints.down('laptop')]: {
            flexDirection: 'column',
        },
    },
    imageContainer: {
        flex: 1,
    },
    image: {
        height: '100%',
        objectFit: 'cover',
        maxHeight: 'calc(100vh - 150px)'
    },
    mainImage: {
        position: 'absolute',
        maxWidth: 'calc(100% - 520px)',
        height: '100%',
        objectFit: 'cover',
        right: 0,
        top: 0,
        minWidth: '50%',

        [theme.breakpoints.down('tablet')]: {
            position: 'relative',
            width: '100%',
            maxWidth: '100%',
            height: 'auto',
        },
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: 32,
        margin: '32px 0',
        gap: 20,
        borderTop: `1px solid ${theme.palette.grey.main}`,
        borderBottom: `1px solid ${theme.palette.grey.main}`,

        [theme.breakpoints.up('tablet')]: {
            padding: 60,
            margin: '60px 0',
            gap: 40,
        },
    },
    footerTitle: {
        color: theme.palette.grey.second,
        textTransform: "uppercase",
        fontFamily: 'Oswald',
        fontWeight: 500,
        width: null,
        fontSize: 28,
        [theme.breakpoints.up('tablet')]: {
            fontSize: 48,
        },
    },
    mediaContainer: {
        display: 'flex',
        gap: 20,
    },
    imageSteam: {
        height: 48,
        width: 48,
        backgroundColor: theme.palette.grey.second,

        [theme.breakpoints.up('tablet')]: {
            height: 88,
            width: 88,
        },

        '&:hover': {
            backgroundColor: theme.palette.orange.main,
            transition: 'background-color 300ms linear, filter 300ms linear',
        },

        '& img': {
            fill: 'black',
            filter: 'brightness(0%)',
            transition: 'background-color 300ms linear, filter 300ms linear',
            '&:hover': {
                fill: 'white',
                filter: 'brightness(100%)',
                transition: 'background-color 300ms linear, filter 300ms linear',
            },
        },
    },
    copyright: {
        color: theme.palette.grey.second,
        fontFamily: 'Montserrat',
        fontSize: 16,
        paddingBottom: 32,

        [theme.breakpoints.up('tablet')]: {
            paddingBottom: 60,
        },
    },
}));

const OurProjectPage = ({data}) => {
    const {
        Title,
        Description,
        Image,
        Images,
        Made_for,
        Year,
        Platform,
        Steam,
        App_store,
        Play_market,
        Meta_store,
        Nintendo_eShop,
        Xbox_store,
        Playstation_store,
        Text_above_pictures,
        Text_under_pictures,
        Seo_title,
        Seo_description,
    } = data.strapiOurProject;

    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.up('mobile'));
    const tablet = useMediaQuery(theme.breakpoints.up('tablet'));
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return <div className={cx(classes.blockContainer, {
        [classes.hideBlock]: !isMounted,
    })}
                style={{zIndex: isMounted ? 1 : 0}}
    >
        <Seo title={Seo_title} description={Seo_description}/>
        <div className={classes.textContentContainer}>
            <img src={`${STRAPI_URL}${Image.url}`}
                 alt="project"
                 className={classes.mainImage}
                 loading="lazy"
                 fetchPriority="low"
            />

            <div className={classes.textContainer}>
                <div className={classes.title}>
                    <ScaleText
                        minFontSize={tablet ? 100 : mobile ? 54 : 44}
                        maxFontSize={tablet ? 140 : mobile ? 64 : 50}
                    >
                        {Title}
                    </ScaleText>
                </div>
                {
                    Made_for ? <>
                        <div className={cx(classes.smallText)}>
                            {'Made for'}
                        </div>
                        <div className={cx(classes.subtitle)}>
                            {Made_for}
                        </div>
                    </> : <></>
                }

                {
                    Year ? <>
                        <div className={cx(classes.smallText)}>
                            {'Year'}
                        </div>
                        <div className={cx(classes.subtitle)}>
                            {Year}
                        </div>
                    </> : <></>
                }

                {
                    Platform ? <>
                        <div className={cx(classes.smallText)}>
                            {'Platform'}
                        </div>
                        <div className={cx(classes.subtitle)}>
                            {Platform}
                        </div>
                    </> : <></>
                }

                <div className={cx(classes.text, classes.textMaxWidth)}>
                    {Description}
                </div>
            </div>

        </div>

        <div className={classes.detailContentContainer}>
            <div
                className={cx(classes.text)}
                dangerouslySetInnerHTML={{__html: Text_above_pictures.data.childMarkdownRemark.html}}
            />
            <div className={classes.imagesContainer}>
                {
                    Images.map((image) => {
                        return <div key={image.url} className={classes.imageContainer}><img
                            src={`${STRAPI_URL}${image.url}`} alt="Logo"
                            className={classes.image} loading="lazy"/></div>
                    })
                }
            </div>

            <div
                className={cx(classes.text)}
                dangerouslySetInnerHTML={{__html: Text_under_pictures.data.childMarkdownRemark.html}}
            />

            <div className={cx(classes.footer)}>
                <div className={cx(classes.footerTitle)}>{'Play the project'}</div>
                <div className={classes.mediaContainer}>
                    {Steam && <a href={Steam} target="_blank">
                        <div className={classes.imageSteam}>
                            <img src={steam}
                                         alt="Steam"
                            />
                        </div>
                    </a>}
                    {App_store && <a href={App_store} target="_blank">
                        <div className={classes.imageSteam}>
                            <img src={appStore}
                                         alt="AppStore"
                            />
                        </div>
                    </a>}
                    {Play_market && <a href={Play_market} target="_blank">
                        <div className={classes.imageSteam}>
                            <img src={playMarket}
                                         alt="PlayMarket"
                            />
                        </div>
                    </a>}
                    {Meta_store && <a href={Meta_store} target="_blank">
                        <div className={classes.imageSteam}>
                            <img src={meta}
                                         alt="Meta"
                            />
                        </div>
                    </a>}
                    {Nintendo_eShop && <a href={Nintendo_eShop} target="_blank">
                        <div className={classes.imageSteam}>
                            <img src={nintendoEshop}
                                         alt="NintendoEshop"
                            />
                        </div>
                    </a>}
                    {Xbox_store && <a href={Xbox_store} target="_blank">
                        <div className={classes.imageSteam}>
                            <img src={xboxStore}
                                         alt="XboxStore"
                            />

                        </div>
                    </a>}
                    {Playstation_store && <a href={Playstation_store} target="_blank">
                        <div className={classes.imageSteam}>
                            <img src={playstationStore}
                                         alt="Steam"
                            />
                        </div>
                    </a>}
                </div>
            </div>
            <div className={cx(classes.copyright)}>{data.strapiContactUs.Copyright}</div>
        </div>
    </div>
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiContactUs {
      Copyright
    }
    strapiOurProject(slug: { eq: $slug }) {
       Title
       Description
       Image {
         url
       }
       Images {
         url
       }
       Year
       Made_for
       Platform
       Steam
       App_store
       Play_market
       Meta_store
       Nintendo_eShop
       Xbox_store
       Playstation_store
       Subtitle
       slug
       Seo_title,
       Seo_description
       childStrapiOurProjectTextAbovePicturesTextnode {
         Text_above_pictures
       }
       Text_above_pictures {
         data {
           childMarkdownRemark {
             html
           }
         }
       }
       Text_under_pictures {
         data {
           childMarkdownRemark {
             html
           }
         }
       }
    }
  }
`


export default OurProjectPage
